// custom typefaces
require("@fontsource/montserrat/variable.css")
require("@fontsource/merriweather")
// normalize CSS across browsers
require("./src/normalize.css")
// custom CSS styles
// import "./src/style.css"

// Highlighting for code blocks
// import "prismjs/themes/prism.css"

require("./src/styles/style.scss")

// module.exports.onRouteUpdated = () => {
//     console.log('HERE')
// }

// IMPORT JS HERE?
